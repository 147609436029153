<template lang="pug">
  .select-meeting_space(
    :class="{'error-field': error, disabled: !courseItemCity, 'no-meeting-space': selectedNoMeetingSpace}")
    transition(name="cardX" mode="out-in")
      .meeting-scape__wrapper(v-if="!editableCard" :key="'meetingSpaces_wrapper'" @click="toggle")
        .meeting-space-content
          transition(name="fade" mode="out-in")
            span.placeholder-text(v-if="!selectedNoMeetingSpace" :key="'placeholder_default'") {{ placeholder }}
            span.placeholder-text(v-else :key="'placeholder_no-meeting-space'") No meeting space
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="16" y="16" viewBox="0 0 600 450" width="16" height="16" :class="['arrow-icon', {showed: showing}]">
            <g xmlns="http://www.w3.org/2000/svg">
              <path :fill="arrowColor" d="M505.752,123.582c-8.331-8.331-21.839-8.331-30.17,0L256,343.163L36.418,123.582c-8.331-8.331-21.839-8.331-30.17,0    s-8.331,21.839,0,30.17l234.667,234.667c8.331,8.331,21.839,8.331,30.17,0l234.667-234.667    C514.083,145.42,514.083,131.913,505.752,123.582z"/>
            </g>
          </svg>
        transition(name="lowFade")
          .meeting-space-list(v-if="showing" v-click-outside="onOutsideClick")
            .meeting-space__item.no-meet-space(@click.stop="selectMeetingSpace(noMeetingSpace)")
              p.item-text No meeting space
            .meeting-space__item(
              v-if="meetingSpacesList"
              v-for="option in meetingSpacesList"
              :class="['selected']"
              @click.stop="selectMeetingSpace(option)"
            )
              p.item-text {{ option.name }}
                span {{ option.city }}, {{ option.postal_code }}, {{ option.address }}
            span(v-if="!meetingSpacesList.length").meeting-space__empty Meeting spaces has not been created for this city
      .meeting-scape__selected(v-else :key="'meetingSpaces_selected'")
        .meeting-space-select
          .item-flex-row
            span.space-name {{ selectedMeetingSpace.name }}
            span.space-link(@click="editSpace") Edit
          .item-flex-row
            span.space-description {{ selectedMeetingSpace.city }}, {{ selectedMeetingSpace.postal_code }}, {{ selectedMeetingSpace.address }}
            span.space-link
              a(:href="selectedMeetingSpace.route_link" target="_blank") Route link
                img(src="@/assets/img/ui/link-icon.svg").link-icon
    span.error-message(v-if="!editableCard") {{ errorMessages }}
</template>

<script>
import vClickOutside from 'v-click-outside'
import showingMixin from '@/mixins/showing.mixin'
import BASE_COURSE_DAY from '@/app/admin/modules/course_managment/core/baseCourseDay';

export default {
  name: 'selectMeetingSpace',

  directives: {
    clickOutside: vClickOutside.directive
  },

  mixins: [showingMixin],

  props: {
    placeholder: String,
    meetingSpacesList: Array,
    value: Object,
    courseItemCity: Number,
    error: Boolean,
    errorMessages: String,
    editable: Boolean
  },

  data: () => ({
    editableCard: false,
    selectedMeetingSpace: BASE_COURSE_DAY().meeting_space,
    noMeetingSpace: {
      address: '',
      city: '',
      id: -1,
      main_city: '',
      name: 'No meeting room',
      postal_code: '',
      route_link: '',
      textToShow: ''
    }
  }),

  computed: {
    selectedNoMeetingSpace() {
      return this.selectedMeetingSpace.id === -1;
    },

    arrowColor() {
      if (this.error) return '#ff7575'
      if (!this.courseItemCity && !this.selectedMeetingSpace) return '#bbc4ca'
      return '#4e7fed'
    }
  },

  async mounted() {
    if (this.value && this.editable) {
      await this.$emit('loadMeetingSpaces', this.courseItemCity)
      if (!this.value.meeting_space) {
        this.selectedMeetingSpace = this.noMeetingSpace
        this.$emit('change', this.noMeetingSpace)
        return
      }
      if (this.value.meeting_space) {
        this.selectedMeetingSpace = this.value.meeting_space
        this.editableCard = true

      }
    }
  },

  methods: {
    async selectMeetingSpace(item) {
      this.toggle()
      this.selectedMeetingSpace = item
      this.$emit('change', item)
      if (item.id !== this.noMeetingSpace.id) this.editableCard = true
    },

    editSpace() {
      this.editableCard = false
      this.$emit('change', '')
    },

    onOutsideClick() {
      this.toggle()
      this.$emit('click:outside')
    }
  },

  watch: {
    'courseItemCity'() {
      this.editSpace()
    }
  }
}
</script>

<style lang="scss">
.select-meeting_space {
  display: block;
  position: relative;
  min-height: 100px;

  &.error-field {
    .meeting-scape__wrapper {
      border-color: $error-color !important;
      background-color: #ffe9e9 !important;

      .meeting-space-content {
        .placeholder-text {
          color: $error-color !important;
        }
      }
    }
  }

  &.no-meeting-space {
    cursor: pointer;

    .meeting-scape__wrapper {
      background-color: $body-bg;
      transition: all 0.25s ease-in-out;
    }
  }

  &.disabled {
    cursor: not-allowed !important;

    .meeting-scape__wrapper {
      pointer-events: none;
      border: dashed 1px #bbc4ca;
      background-color: #f4f7f9;
      transition: all 0.25s ease-in-out;

      .meeting-space-content {
        .placeholder-text {
          color: #bbc4ca;
        }
      }
    }
  }

  .meeting-scape__wrapper {
    display: block;
    position: relative;
    width: 100%;
    min-height: 45px;
    padding: 15px 10px;
    border-radius: 8px;
    border: dashed 1px #b7cdfe;
    background-color: #ecf2ff;
    cursor: pointer;
    transition: all 0.25s ease-in-out;

    .meeting-space-content {
      display: flex;
      justify-content: center;

      .placeholder-text {
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: $primary-color;
        transition: all 0.1s ease-in-out;
        margin-left: auto;

        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }

      .arrow-icon {
        display: block;
        margin-left: auto;
        transition: all 0.15s ease-in-out;
        transform: rotate(0deg);

        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        &.showed {
          transition: transform 0.15s ease-in-out;
          transform: rotate(-180deg);
        }
      }
    }

    .meeting-space-list {
      position: absolute;
      overflow-y: scroll;
      min-height: 200px;
      height: 100%;
      max-height: 250px;
      left: 0;
      right: 0;
      top: -201px;
      bottom: 0;
      background-color: $body-bg;
      z-index: 1;
      width: 100%;
      border-radius: 8px;
      box-shadow: 0 2px 6px 0 rgb(0 0 0 / 5%);
      border: 1px solid $border-color;

      .meeting-space__item {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        padding: 15px;
        transition: all 0.15s ease-in-out;

        &:hover {
          background-color: #ecf2ff;
          transition: all 0.15s ease-in-out;
        }

        &.no-meet-space {
          width: auto;
          padding: 15px;
          border-bottom: 1px solid $border-color;

          .item-text {
            font-family: 'Roboto', sans-serif;
            justify-content: center;
            color: $primary-color;
            font-size: 12px;
            font-weight: 500;
            line-height: normal;
          }
        }

        .item-text {
          width: 100%;
          margin: 0;
          padding: 0;
          font-family: 'Roboto', sans-serif;
          font-size: 11px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #000000;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;

          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;

          span {
            font-size: 10px;
            font-weight: 500;
            text-align: right;
            color: $label-color;
            line-height: normal;
            margin-left: 10px;
            width: 55%;
          }
        }
      }

      .meeting-space__empty {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 15px 10px;
        font-family: $font-roboto;
        font-size: 12px;
        color: $label-color;
      }
    }
  }

  .meeting-scape__selected {
    position: relative;
    padding: 15px;
    border-radius: 8px;
    background-color: #ecf2ff;
    transition: all 0.1s ease-in-out;
    outline: none;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.06);

    .meeting-space-select {
      display: flex;
      flex-direction: column;

      .item-flex-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 15px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      span {
        font-family: 'Roboto', sans-serif;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #232428;
      }

      .space-name {
      }

      .space-description {
        font-size: 12px;
        font-weight: 500;
        color: $label-color;
      }

      .space-link {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 12px;
        font-weight: 500;
        color: $primary-color;
        cursor: pointer;

        a {
          display: flex;
          text-decoration: none;
          min-width: 73px;

          .link-icon {
            display: block;
            margin-left: 5px;
          }
        }
      }
    }
  }
}
</style>
