<template lang="pug">
  .double.course-body
    .double-left
      crm-input(
        :class="'name__input'"
        :value="courseItem.name"
        placeholder="Type course name here"
        :error="formErrors.hasError('name')"
        :error-messages="formErrors.fields.name"
        @input="update('name', $event)"
      )

      app-select(
        :options="crmCourseLocations"
        :value="courseItem.city"
        @input="update('city', $event)"
        label="Course location"
        placeholder="Choose city"
        :error="formErrors.hasError('city')"
        :error-messages="formErrors.fields.city"
        slug-key='ID'
        slug-value='name'
        searchable
        required-field
      )
        template(v-slot:before="selectCityBefore")
          img(src="@/assets/img/ui/location.svg" v-if="!selectCityBefore.selectedOption")
          span.option-slug(v-else) {{ selectCityBefore.selectedOption.code }}

        template(v-slot:option="selectProps")
          span.option-slug {{ selectProps.option.code }}
          span {{ selectProps.option.name }}

      .double.items
        .double-left
          crm-datepicker(
            :ref="'crmDatepicker'"
            :value="courseItem.date"
            @input="update('date', $event)"
            hideDetails
            no-title
          )
            template(v-slot:activator="{showDatepicker, formattedDate, on}")
              crm-input(
                :label="'Course date'"
                :class="'date__input'"
                :iconLink="'ui/calendar.svg'"
                :value="formattedDate"
                :placeholder="'Select dates in calendar'"
                :error="formErrors.hasError('date')"
                :error-messages="formErrors.fields.date"
                :readonly="'readonly'"
                @input="update('date', $event)"
                v-on="on"
                @click="showDatepicker"
                required-field
              )

        .double-right
          crm-input(
            :min="0"
            :max="1000"
            label="Course limits"
            placeholder="Set limits"
            :value="courseItem.max_students"
            :type="'number'"
            :iconLink="'ui/person.svg'"
            @input="update('max_students', $event)"
            :class="'max_students__input'"
            :error="formErrors.hasError('max_students')"
            :error-messages="formErrors.fields.max_students"
          )

      crm-textarea(
        label="Notes"
        :value="courseItem.notes"
        :maxlength="5000"
        placeholder="Leave your notes here"
        @change="update('notes', $event)"
      )

      .row-block
        span.required *
        p.dialog-section-title MEETING SPACE

      select-meeting-space(
        :editable="editable"
        :value="courseItem"
        placeholder="Choose meeting space"
        :meetingSpacesList="meetingSpacesList"
        :courseItemCity="courseItem.city"
        @loadMeetingSpaces="$emit('update:city', $event)"
        @change="update('meeting_space', $event)"
        :error="formErrors.hasError('meeting_space')"
        :error-messages="formErrors.fields.meeting_space"
      )

    .double-right
      .leaders-and-sessions
        course-configuration-item(
          :formErrors="formErrors"
          :options="courseItem.course_day_sections"
          @remove:item="$emit('remove:item', $event)"
        )
</template>

<script>
import crmInput from '@/components/global/actions/BaseInput.vue'
import selectCity from './selectCity.vue'
import crmTextarea from '@/components/global/actions/BaseTextarea.vue'
import selectMeetingSpace from './selectMeetingSpace.vue'
import crmDatepicker from '@/app/admin/components/CrmDatepicker.vue'
import courseConfigurationItem from './courseConfigurationItem.vue'
import appSelect from "@/components/global/actions/BaseSelect.vue"

export default {
  name: 'courseConfigurationBody',

  components: {
    crmInput,
    selectCity,
    crmTextarea,
    selectMeetingSpace,
    crmDatepicker,
    courseConfigurationItem,
    appSelect
  },

  props: {
    courseItem: {
      type: Object,
      required: true
    },
    crmCourseLocations: Array,
    meetingSpacesList: Array,
    formErrors: Object,
    editable: Boolean
  },

  methods: {
    update(field, value) {
      this.$emit(`update:${field}`, value)
    }
  }
}
</script>

<style lang="scss">
.dialog-section-title {
  margin: 15px 0;
  padding: 0;
  color: $label-color;
  text-transform: uppercase;
  letter-spacing: normal;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  font-size: 12px;
  line-height: normal;
}

.double {
  &.course-body {
    .double-left {
      padding: 15px 25px;
      box-shadow: inset 0 -2px 4px 0 rgb(0 0 0 / 6%);
    }

    .double-right {
      padding: 15px 25px;
      background-color: #F4F7F9;
      box-shadow: inset 0 -2px 4px 0 rgb(0 0 0 / 6%);

      .leaders-and-sessions {
        display: flex;
        flex-direction: column;

        p {
          margin-top: 0;
        }
      }
    }
  }

  &.items {
    .double-left {
      padding: 0 15px 0 0;
      border-right: none;
      box-shadow: none;

      @include media("<=tablet") {
        padding-right: 0;
      }
    }

    .double-right {
      padding: 0;
      background-color: transparent;
      box-shadow: none;
    }
  }
}

.name__input {
  margin: 0;

  input {
    font-size: 16px;

    &::placeholder {
      font-size: 16px;
    }
  }

  &.filled {
    input {
      border-color: transparent;
      box-shadow: none;

      &:focus {
        background-color: transparent !important;
      }

      &:hover {
        background-color: #ECF2FF;
        box-shadow: 0 2px 6px 0 rgb(0 0 0 / 5%);
      }
    }
  }

  input:focus {
    outline: $primary-color;
    border-color: $primary-color;
    box-shadow: 0 2px 6px 0 rgb(0 0 0 / 5%);
  }
}

.row-block {
  .dialog-section-title {
    margin: 0;
    padding: 0;
  }
}

.option-slug {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
  padding: 5px;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  background-color: #F4F7F9;
  color: $input-color;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: normal;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  font-size: 10px;
  line-height: normal;
}
</style>
