<template lang="pug">
  .course-sections
    .course-sections__header
      h3.dialog-section-title LEADERS & SESSIONS
      div.course-sections__bulk
        app-button(text hide-shadow color="primary" v-if="selectedSessions.length" @click.native="selectAll") Select All
        app-select(
          :options="courseLeaders ? courseLeaders: []"
          @input="updateLeaderBulk"
          slugValue="name"
        )
          <template v-slot:field="{toggle}">
            div
              app-button(color="#fff" hide-shadow @click.native="toggle" :disabled="!selectedSessions.length").course-sections__leader
                <i class="material-icons text-primary">account_circle</i>
                span.text-primary Assign
          </template>

    app-card
      cm-session(
        v-for="(option, index) in options"
        :key="index"
        :option="option"
        :sessions="unchoosedSessions"
        v-model="selectedSessions"
        :error="formErrors.hasError('course_day_sections', {nested: 'session', index: index})"
        :error-messages="formErrors.getError('course_day_sections', {nested: 'session', index: index})"
        @click:remove="removeSession(index)"
        @update:leader="updateLeader($event, index)"
        @update:session="updateSession($event, index)"
      )
    app-button(
      block
      dashed
      :disabled="createSectionDisabled"
      @click.native="addSessionConfig"
      :class="{'error-field': formErrors.hasError('sessions')}"
      :error="formErrors.hasError('sessions')"
      :error-messages="formErrors.fields.sessions"
    ).mt-2
      span(:class="{'text-error': formErrors.hasError('sessions') }").text-primary.unselectable Add session +
</template>

<script>
import {mapGetters} from "vuex"
import AppButton from '@/components/global/actions/BaseButton.vue'
import appCard from "@/components/global/BaseCard.vue"
import AppSelect from "@/components/global/actions/BaseSelect.vue"

export default {
  name: 'courseConfigurationItem',

  props: {
    options: {
      type: Array,
      required: true
    },
    formErrors: Object
  },

  inject: ['removeSession', 'updateSessionConfig', 'addSessionConfig'],

  data: () => ({
    selectedSessions: []
  }),

  computed: {
    ...mapGetters({
      courseLeaders: 'crmCourseLeaders/leadersList',
      sessions: 'sessions/items'
    }),

    createSectionDisabled() {
      if (!this.courseLeaders || (!this.options && !this.options.length) || (!this.sessions && this.sessions.length)) return false
      return this.options.length === this.sessions.length
    },

    unchoosedSessions() {
      if (!this.sessions) return []
      return this.sessions.filter(session => {
        let sessionChoosed = this.options.some(option => {
          if (!option.session) return false
          return option.session.id === session.id
        })
        if (sessionChoosed) return false
        return true
      })
    }
  },

  methods: {
    updateLeaderBulk(val) {
      this.options.forEach((option, index) => {
        if(this.selectedSessions.some(session => option.session.name === session))
          this.updateLeader(val, index)
      })
    },

    updateLeader(val, index) {
      this.updateSessionConfig(index, 'leader', val)
    },

    updateSession(val, index) {
      this.updateSessionConfig(index, 'session', val)
    },

    selectAll() {
      this.selectedSessions = this.options.filter(option => option.session).map(option => option.session.name)
    }
  },

  components: {
    appCard,
    AppButton,
    cmSession: () => import('./cmSession.vue'),
    appSelect: () => import('@/components/global/actions/BaseSelect.vue')
  },
}
</script>

<style lang="scss">
.course-sections {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__bulk {
    display: flex;
  }
  &__leader {
    height: 32px;
    .app-button__wrapper {
      display: inline-flex;
      align-items: center;
      i {
        margin-right: 6px;
        font-size: 16px;
      }
    }
  }
}
.icon-close {
  display: flex;
  width: 29px;
  height: 29px;
  transition: all 0.3s ease-in-out;
  transform: scale(0.95);
  cursor: pointer;

  &:hover {
    transform: scale(1.25);
  }
}

</style>
