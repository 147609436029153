<template lang="pug">
  .crm-select(:class="{'field-error': error}")
    span(v-if="requiredField").required *
    span.crm-select-label(v-if="label") {{ label }}
    .crm-select__wrapper(@click="toggle" :class="{'showed': showing}")
      .icons__wrapper
        img(src="@/assets/img/ui/location.svg" v-if="!selectedValue")
        span.option-slug(v-else) {{ getCityInfo[slugKey] }}
      span.selected-option.placeholder(v-if="!selectedValue") {{ placeholder }}
      span.selected-option(v-else) {{ getCityInfo.name }}
      img(src="@/assets/img/ui/arrow-down.svg").arrow-icon

      transition(name="fade")
        .crm-dropdown__container(v-if="showing" v-click-outside="onOutsideClick")
          .dropdown-option(v-for="option in options" :class="{'selected': option.ID === value}")
            .dropdown-option--cell(@click.stop="setOption(option)")
              span.option-slug {{ option[slugKey] }}
              span.option-text {{ option.name }}
    span.error-message {{ errorMessages }}
</template>

<script>
import vClickOutside from 'v-click-outside'
import showingMixin from "@/mixins/showing.mixin";

export default {
  directives: {
    clickOutside: vClickOutside.directive
  },
  mixins: [showingMixin],
  name: 'selectCity',
  props: {
    placeholder: String,
    label: String,
    options: {
      type: Array,
      required: true
    },
    slugKey: {
      type: String,
      default: 'code'
    },
    value: [Object, Number],
    error: Boolean,
    errorMessages: String,
    requiredField: Boolean
  },
  computed: {
    selectedValue: {
      get: function () {
        return this.value
      },
      set: function (value) {
        this.$emit('change', value)
      }
    },
    getCityInfo() {
      return this.options.find(item => item.ID === this.value)
    }
  },
  methods: {
    setOption(option) {
      this.selectedValue = option
      this.toggle()
    },
    onOutsideClick() {
      this.toggle()
      this.$emit('click:outside')
    }
  }
}
</script>

<style lang="scss">
.crm-select {
  display: block;
  position: relative;
  width: 100%;
  margin: 10px 0;
  user-select: none;
  border: none;
  transition: all 200ms linear;

  &.field-error {
    .crm-select__wrapper {
      border-color: $error-color;
    }
  }

  .crm-select-label {
    font-family: 'Roboto', sans-serif;
    font-size: 10px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $label-color;
  }

  .crm-select__wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 0 10px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    border: solid 1px $border-color;
    background-color: #ffffff;
    cursor: pointer;
    transition: all 0.15s ease-in-out;

    .selected-option {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 40px;
      position: relative;
      padding: 10px 5px 10px 0;
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $input-color;
      transition: all 200ms linear;

      &:hover {
        color: rgba(0, 0, 0, .45);
      }

      &.placeholder {
        color: #bbc4ca;
      }
    }

    .arrow-icon {
      display: flex;
      transition: transform 0.15s ease-in-out;
      transform: rotate(0deg);
      margin-left: auto;
    }

    &.showed {
      transition: all 0.15s ease-in-out;
      border: 1px solid #4e7fed;

      .arrow-icon {
        transition: transform 0.15s ease-in-out;
        transform: rotate(-180deg);
      }
    }

    &:focus {
      border-color: #4e7fed;
      outline: #4e7fed;
    }
  }

  .crm-dropdown__container {
    position: absolute;
    overflow-y: scroll;
    height: 300px;
    left: 0;
    right: 0;
    top: 70px;
    bottom: 0;
    background-color: $body-bg;
    z-index: 1;
    width: 100%;
    max-width: 200px;
    border-radius: 8px;
    box-shadow: 0 2px 6px 0 rgb(0 0 0 / 5%);
  }

  .dropdown-option {
    cursor: auto;
    transition: all 0.15s ease-in-out;

    &:hover {
      background-color: #ecf2ff;
      transition: all 0.15s ease-in-out;
    }

    &.selected {
      transition: all 0.15s ease-in-out;
      background-color: #ecf2ff;
    }

    &--cell {
      padding: 5px 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
      user-select: none;
      white-space: nowrap;
      overflow: hidden;
      cursor: pointer;
    }
  }

  .icons__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    width: 24px;
  }

  .option-slug {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    font-size: 10px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: $input-color;
    text-transform: uppercase;
    border-radius: 4px;
    background-color: #f4f7f9;
    padding: 5px;
  }

  .option-text {
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $input-color;
    margin-left: 10px;
  }
}
</style>
